import './vendor'
import './select-picker-options/select-picker-options'
import Form from "./form";
import { initShowCalendarOnClickForDateInputs } from './inputs/date-inputs';

$(function() {
    Form.initTooltipInfo();

    $('.select-all-checkboxes').change(function(e) {
        // e.preventDefault();

        $(this).closest('.checkboxBlock').find('.innerContainer input[type=checkbox]').prop('checked', $(this).is(':checked'));
        
        // nastavení all checkboxu
        var allChecked = true;
        $('.select-all-checkboxes').each(function () {
            // alespon jeden není zaškrtnut
            if (!($(this).is(':checked')))
            {
                allChecked = false;
            }
        });

        $("input.all-checkbox:checkbox").prop('checked', allChecked);
    });
    
    $('.submit-form').change(function(e) {
        if (this.form) {
            this.form.submit();
        }
    });

    $('.checkboxBlock .innerContainer input[type=checkbox]').change(function(e) {
        // e.preventDefault();                      

        $(this).closest('.checkboxBlock').find('.select-all-checkboxes').prop('checked', ($(this).closest('.checkboxBlock').find('.innerContainer input[type=checkbox]:not(:checked)').length === 0));
        
        // nastavení all checkboxu
        var allChecked = true;
        $('.select-all-checkboxes').each(function () {
            // alespon jeden není zaškrtnut
            if (!($(this).is(':checked')))
            {
                allChecked = false;
            }
        });

        $("input.all-checkbox:checkbox").prop('checked', allChecked);
    });   
    
	$(".fancybox").fancybox();
    
    $('body').on('click', '.confirmation', function() {

        var confirmText = $(this).attr('data-confirm-text') ? $(this).attr('data-confirm-text') : 'Opravdu chctete akci provést?';
        
        if (!confirm(confirmText)) 
        {
            return false;
        }
        
        if ($(this).hasClass('confirmation-ajax'))
        {	
			$.nette.ajax(
			{
				url: $(this).attr('href')
			});
            
            return false;
        }
    });
    
    $('.icon-upload-file input[type="file"]').on('change', function (event) {
        $(this).parent().parent().find('.icon-form img').attr('src', URL.createObjectURL(event.target.files[0]));
    });
    
    initDateForm();
    
    initLanguage();
    
    initFancyboxGallery();

    changeForm();

    initJsTree();

    // když se zaškrtne kategorie, automaticky zaškrtneme i vyloučit z top a zašedneme
    $('.category_tag_form input[name="category"]').change(function() {
        $('.category_tag_form input[name="exclude"]').prop('checked', this.checked);
        $('.category_tag_form input[name="exclude"]').prop('disabled', this.checked);
    });

    initShowCalendarOnClickForDateInputs();
});

function initFancyboxGallery() {   
    // Rucni otevreni fancyboxu po kliknuti na prvky v aplikaci
    $('#fancybox-gallery').on('click', '.fancybox', function() {
        
        if ($(this).attr('data-fancybox-group'))
        {
            // Pokud je kliknuto na prvek ktery ma galerii            
            const $objects = $('#fancybox-gallery .fancybox[data-fancybox-group=' + $(this).attr('data-fancybox-group') + ']');            
            
            $.fancybox.open(
                $objects,
                {
                    index: $objects.index($(this))
                }
            );
         
            return false;            
        }                               
        
        $.fancybox.open($(this));
         
        return false;
    });
}


function initDateForm() {
    $('.leaflet-form .checkbox-date input[type="checkbox"]').change(function() {

        // zobrazím checkbox-end
        if ($(this).is(':checked')) {
            $('.leaflet-form .checkbox-end').show();
            
            $('.leaflet-form .valid-from input').prop('disabled', true);

            // vypneme validaci na filled
            replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':filled', ':filled-disable');

            // musíme vypnut validaci na datum, jinak nejde odeslat form v případě, že je prázdný
            replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':pattern', ':pattern-disable');

            // vyprázdníme valid-to
            $('.leaflet-form .valid-to input').val('');
        }
        else {
            $('.leaflet-form .checkbox-end').hide();
            
            $('.leaflet-form .valid-from input').prop('disabled', false);

            // zapneme validaci na filled
            replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':filled-disable', ':filled');
            replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':pattern-disable', ':pattern');
        }
    });
    
    if ($('.leaflet-form .checkbox-date input[type="checkbox"]').is(':checked')) {
        $('.leaflet-form .checkbox-end').show();
        
        $('.leaflet-form .valid-from input').prop('disabled', true);

        // vypneme validaci na filled
        replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':filled', ':filled-disable');

        // musíme vypnut validaci na datum, jinak nejde odeslat form v případě, že je prázdný
        replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':pattern', ':pattern-disable');
    }
    else {
        $('.leaflet-form .checkbox-end').hide();
        
        $('.leaflet-form .valid-from input').prop('disabled', false);

        // zapneme validaci na filled
        replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':filled-disable', ':filled');
        replaceRulesOp($('.leaflet-form .valid-to input'), 'data-nette-rules', ':pattern-disable', ':pattern');
    }
}

function initLanguage(){
    $('.language input[type="checkbox"]').change(function(e){
        
        if (!$(this).is(':checked')) {
            $('.language .language-multiselectbox').hide();
        }
        else {
            $('.language .language-multiselectbox').show();
        }
    });
    
    // při inicializaci
    if ($('.language input[type="checkbox"]').is(':checked')) {
        $('.language .language-multiselectbox').show();
    }
    else {
        $('.language .language-multiselectbox').hide();
    }
}

function changeForm()
{
    $('.change-form').change(function(e) {
        e.preventDefault();

        let value = $(this).val();

        if (value === '')
        {
            return;
        }

        let link = $(this).parent().parent().data('link');

        console.log(link);

        $.nette.ajax({
            url: link,
            data: {'value': value},
            method: 'GET',
            complete: function () {

            // renicializovat handle a selectboxy
            changeForm();
            initSelectBoxes();
        }
    });
    });
}

function replaceRulesOp(element, attribute, search, replace) {
    let rulesJson = element.attr(attribute);

    // pokud je undefine, tak musíme přeskočit
    if (rulesJson === undefined) {
        return;
    }

    let rules = JSON.parse(rulesJson);

    // projdeme rules a pokusíme se najít op s patern
    rules.map((item) => {
        if (item.op === search) {
            item.op = replace;
        }
    });

    // složím a uložím
    element.attr(attribute, JSON.stringify(rules));
}

function initJsTree() {

    initJsTreeCore();

    // odeslat ajax půjde až po init jstree
    $('#category_tag').bind('ready.jstree', function() {
        $('.category_tag_save').on("click",function() {

            const v = $('#category_tag').jstree(true).get_json('#', {flat:true});
            const tree = JSON.stringify(v);
            const button = $(this);

            const link = button.data('link');

            $.nette.ajax({
                url: link,
                data: {'tree': tree},
                method: 'POST',
                complete: function () {
                    //povolíme tlačítko
                    button.prop('disabled', false);

                    // do tlačítka znova vložíme classu btn-primary
                    button.addClass('btn-primary');

                    // znova inicializujeme strom
                    initJsTreeCore();
                },
                start: function () {
                    // z tlačítka odstraníme classu btn-primary
                    button.removeClass('btn-primary');

                    // nastavíme disable
                    button.prop('disabled', true);
                }
            });
        });
    });

    // doplnění id kategorií do hidden inputu category_tag před odesláním formu z jstree pluginu
    $('.category_tag_form button[name=save], .category_tag_form button[name=save_close]').on("click", function() {
        const v = $('#category_tag').jstree(true).get_json('#', {flat:true});
        const tree = JSON.stringify(v);

        $('.category_tag_form input[name=category_tag]').val(tree);
    });

    $('.category_tag_form input[name=save], .category_tag_form input[name=save_close]').on("click", function() {
        const v = $('#category_tag').jstree(true).get_json('#', {flat:true});
        const tree = JSON.stringify(v);

        $('.category_tag_form input[name=category_tag]').val(tree);
    });
}

function initJsTreeCore() {

    // rozbalovací tlačítko
    $('#category_tag').bind('ready.jstree', function() {

        // tlačítko na rozbalení stromu
        $('.category_tag_expand').on('click', function () {
            $("#category_tag").jstree("open_all");
        });

        // tlačítko sbalit vše
        $('.category_tag_collapse').on('click', function () {
            $("#category_tag").jstree("close_all");
            $("#category_tag").jstree('open_node', 0);
        });
    });

    const plugins = $('#category_tag').data('plugins');

    $('#category_tag').jstree({
        "core": {
            "check_callback": true,
            "data": {
                "url": $('#category_tag').data('json-link')
            }
        },
        'plugins': plugins,
        "contextmenu": {
            'items': customJsTreeMenu
        }
    });
}

function customJsTreeMenu($node) {
    const tree = $("#category_tag").jstree(true);

    let items = {
        "Create": {
            "separator_before": false,
            "separator_after": false,
            "label": "Přidat kategorii",
            "action": function () {
                $node = tree.create_node($node, {text: 'Nová kategorie'});
                tree.deselect_all();
                tree.select_node($node);
            }
        },
        "Rename": {
            "separator_before": false,
            "separator_after": false,
            "label": "Přejmenovat",
            "action": function () {
                // při editaci zobrazíme pouze název kategorie v daném jazyce
                $node['text'] = getCleanText($node['text']);
                tree.edit($node);
            }
        },
        "Remove": {
            "separator_before": false,
            "separator_after": false,
            "label": "Odstranit",
            "action": function () {
                if (confirm('Opravdu chcete smazat ?')) {
                    tree.delete_node($node);
                }
            }
        }
    }

    // Pokud nemůžeme kategorii odstranit, odstraníme pro to možnost.
    if ($node.original.canBeRemoved === false) {
        delete items.Remove;
    }

    return items;
}

/**
 * Vrátí text v závorce - překlad pro aktuální jazyk.
 * @param text
 * @returns {string}
 */
function getCleanText(text) {
    let regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(text);

    if (matches === null || typeof matches[1] === 'undefined') {
        return text;
    }

    return matches[1];
}
