class Form {

    /**
     * K daným labelům doplní ikonku informace.
     */
    static initTooltipInfo() {
        $('label[data-img-info]').each(function () {
            $(this).append('<img src=/www/img/info.png alt="Info" class="icon-info">');
        });
    }
}

export default Form