const DATE_INPUTS_SELECTOR = 'input[type="date"], input[type="time"], input[type="datetime"], input[type="datetime-local"]';

export const initShowCalendarOnClickForDateInputs = () => {
    const dateInputs = document.querySelectorAll(DATE_INPUTS_SELECTOR);

    if (!dateInputs || dateInputs.length === 0) {
        return;    
    }

    dateInputs.forEach(function(input) {
        input.addEventListener('click', function() {
            input.showPicker();
        });
    });
}
